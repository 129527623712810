
import { keymap } from '@/config'
import FormInput from 'common/form/inputs/FormInput.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEmailInput',
  components: { FormInput },
  setup () {
    return {
      defaultValue: localGet(keymap.user.email),
    }
  },
})
